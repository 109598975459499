<template>
    <div class="catalogues__modal">
        <div class="catalogues__modal__background">
            <div class="catalogues__modal__content" v-if="!isLoading">
                <div class="catalogues__modal__content__data">
                    <div class="technical__modal__content__data__question">
                        <label for="branch-name" class="technical__modal__content__data__question__label">
                            Nombre de la sucursal:
                        </label>
                        <input class="technical__modal__content__data__question__input" type="text" name="branch-name"
                            id="technical-code" placeholder="Nombre de la sucursal" v-model="branch.name">
                    </div>
                    <div class="technical__modal__content__data__question">
                        <label for="branch-phone" class="technical__modal__content__data__question__label">
                            Número teléfono de la sucursal:
                        </label>
                        <input class="technical__modal__content__data__question__input" type="text" name="branch-phone"
                            id="branch-phone" placeholder="Número teléfono de la sucursal" v-model="branch.phone">
                    </div>

                    <div class="technical__modal__content__data__question">
                        <label for="branch-address" class="technical__modal__content__data__question__label">
                            Dirección de la sucursal (Copiar enlace de Google Maps):
                        </label>
                        <input class="technical__modal__content__data__question__input" type="text" name="branch-address"
                            id="branch-address" placeholder="Dirección de la sucursal" v-model="branch.address">
                    </div>
                    <button class="catalogues__modal__content__data__create" @click="createBranchHelper">
                        Añadir Sucursal
                    </button>
                </div>
                <p class="catalogues__modal__content__x" @click="closeModal">x</p>
            </div>
            <div class="catalogues__modal__loader" v-else>Creando Sucursal...</div>
        </div>
    </div>
</template>
    
<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            branch: {
                name: "",
                address: "",
                phone: "",
            },
            isLoading: false,
        };
    },

    methods: {
        closeModal() {
            this.$emit("closeModal");
            this.cleanBranch();

        },

        check() {
            console.log(this.branch)
        },

        async createBranchHelper() {
            try {
                if (
                    !this.branch.name ||
                    !this.branch.address ||
                    !this.branch.phone
                ) return;

                this.isLoading = true;
                await this.createBranch(this.branch);
                this.$toast.success("Se agregó la Sucursal");
                this.isLoading = false;
                this.closeModal();
            } catch (e) {
                console.log(e)
                this.$toast.error("Ocurrió un error al crear la Sucursal");
            }
        },

        cleanBranch() {
            this.branch.name = ''
            this.branch.address = ''
            this.branch.phone = ''
        },

        ...mapActions("vCard", ["createBranch"]),
    },
};
</script>
    
<style>
</style>
    